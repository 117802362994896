//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  name: 'FooterBottom',
  computed: {
    ...mapState({
      partners: (state) => state.partners,
    }),
  },
}
